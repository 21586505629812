import React, { useState, useEffect, useLayoutEffect } from 'react';
import { graphql } from 'gatsby';
import ReactFullpage from '@fullpage/react-fullpage';
import _ from 'lodash';
import gsap from 'gsap';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link';
import BackgroundImage from '../components/backgroundImage';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Loader from "../components/loader";
import SocialShare from '../components/socialShare';
import DefaultImg from '../../static/landing-page-bg.jpg';

const SEL = 'decade-section';
const SECTION_SEL = `.${SEL}`;

const Home = ({ data, location }) => {
  if (typeof window !== "undefined" && window.sessionStorage.getItem('splashFirstLoadDone') === null) {
    window.sessionStorage.setItem('splashFirstLoadDone', 1);
  }

  const [isShowing, showSplash] = useState(typeof window !== "undefined" && window.sessionStorage.getItem('splashFirstLoadDone'));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      showSplash(window.sessionStorage.getItem('splashFirstLoadDone'));
    }
  }, [isShowing]);

  const [isHover, setHovered] = useState(false);

  const allDecades = data.allDecades.nodes;
  const stories = data.allStories.nodes || [];
  const first = 1;

  const decades = allDecades.sort((a, b) => {
    if (a.value.indexOf('Pre-') !== -1) return -1;
    if (b.value.indexOf('Pre-') !== -1) return 1;

    return parseInt(a) - parseInt(b);
  });

  const groupDecades = [];
  const j = decades.length;
  let subset;

  const rotate = (arr, count = 1) => [...arr.slice(count, arr.length), ...arr.slice(0, count)];

  for (var i = 0; i < j; i++) {
    const rotateDecades = rotate(decades, i);
    const prev = rotateDecades[rotateDecades.length - 1];
    subset = rotateDecades.slice(0, 4);

    subset.unshift(prev);

    groupDecades.push({
      decades: subset,
      color_scheme: subset[first].color_scheme,
      currentDecade: subset[first].value,
    });
  }

  const decadeAnchors = _.map(decades, decade => _.toLower(decade.value));

  const title = _.replace(location.hash, '#', '');
  const image = data.allFile.nodes.find((img) => img.name === title);

  const dataOptions = {
    url: title ? location.href : location.origin,
    title: `${title ? `${_.upperFirst(title)} -` : ''} ${data.site.siteMetadata.title}`,
    image: `${image !== undefined ? `${data.site.siteMetadata.url}${image.publicURL}` : `${data.site.siteMetadata.url}${DefaultImg}`}`,
    description: `${data.site.siteMetadata.description}`
  };

  const bgColor = {
    "dark-blue-scheme": "#1f323c",
    "orange-scheme": "#a55502",
    "blue-scheme": "#89a6aa"
  };

  const horizontalAnimation = ({ length }, el, direction) => {
    const directionTo = direction === 'left' ? '-100%' : '100%'
    const directionFrom = direction === 'left' ? '100%' : '-100%'
    const cover = document.querySelectorAll('.' + el)[0];
    const seconds = length
    const wait = seconds / 10;
    const half = (seconds - wait) / 2;

    return gsap.timeline()
      .set(cover, {
        y: 0,
        x: directionFrom,
        display: "block",
        force3D: true
      })
      .to(cover, {
        x: '0%',
        ease: "power1.easeInOut",
        duration: seconds / 2,
        force3D: true,
        rotation: 0.01,
        yoyo: true
      })
      .set(document.querySelectorAll('.main-site')[0], { opacity: 0, force3D: true })
      .to(cover, {
        x: directionTo,
        ease: "power1.easeIn",
        duration: seconds / 2,
        force3D: true,
        rotation: 0.01,
        yoyo: true
      }, `+=${wait}`)
  }

  const reveal = (entry, node) => {
    return gsap.from(
      node.querySelectorAll('.reveal-animate'),
      {
        autoAlpha: 0,
        y: '+=50',
        duration: .5,
        stagger: 0.1,
        force3D: true,
        ease: "power1.easeInOut",
        delay: 1
      },
    )
  }

  const decadeBgScale = (decade) => {
    if (title === decade || _.isEmpty(title) && decade.indexOf('Pre-') !== -1 || title === _.kebabCase(decade)) {
      setHovered(true);
    }
  }


  return (<>
    {isShowing === '1' ? <Loader /> : ''}
    <Layout noBackLink={true}>
      <SEO
        title={dataOptions.title}
        url={dataOptions.url}
        image={dataOptions.image}
        description={dataOptions.description}
      />

      <ReactFullpage
        // debug /* Debug logging */
        licenseKey={'YOUR_KEY_HERE'} // Get one from https://alvarotrigo.com/fullPage/pricing/
        anchors={decadeAnchors}
        sectionSelector={SECTION_SEL}
        continuousVertical={true}
        paddingTop={'110px'}

        render={({ state, fullpageApi }) => (
          <ReactFullpage.Wrapper>
            {groupDecades.length && groupDecades.map((item, index) => (
              <div className={`${SEL} decade-container ${item.color_scheme}`} key={index}>
                <Container fluid="lg" className="px-sm-0">
                  <Row noGutters>
                    <Col
                      lg={{ span: 2, offset: 1 }}
                      md={{ span: 2, offset: 1 }}
                      className="d-none d-sm-none d-md-block"
                    >
                      <div className="decade-timeline-wrapper">
                        {item.decades.map((decade, index) => (index === 1 ? (
                          <div className="current-decade" key={index}>
                            <div className="decade-title ">
                              <h1 className="script-heading">
                                <TransitionLink
                                  preventScrollJump
                                  to={_.kebabCase(decade.value)}
                                  // replace
                                  onMouseOver={() => decadeBgScale(decade.value)}
                                  onMouseOut={() => setHovered(false)}
                                  exit={{
                                    length: 1,
                                    trigger: ({ exit }) => horizontalAnimation(exit, `transition-cover-${decade.value}`, 'right'),
                                  }}
                                  entry={{
                                    delay: .5,
                                    trigger: ({ entry, node }) => reveal(entry, node),
                                  }}
                                  dangerouslySetInnerHTML={{ __html: decade.value.indexOf('Pre-') !== -1 ? _.replace(decade.value, 'Pre-', `<span>Pre-</span>`) : decade.value }}
                                ></TransitionLink>
                              </h1>
                            </div>
                            <TransitionLink
                              preventScrollJump
                              to={_.kebabCase(decade.value)}
                              className="explore-arrow"
                              onMouseOver={() => setHovered(true)}
                              onMouseOut={() => setHovered(false)}
                              exit={{
                                length: 1,
                                trigger: ({ exit }) => horizontalAnimation(exit, `transition-cover-${decade.value}`, 'right'),
                              }}
                              entry={{
                                delay: .5,
                                trigger: ({ entry, node }) => reveal(entry, node),
                              }}
                            >
                              <span>
                                <span className="label">Explore Decade</span>
                                <span className="long-arrow" />
                              </span>
                            </TransitionLink>
                            <TransitionPortal level="top">
                              <div
                                className={`transition-cover-${decade.value}`}
                                style={{
                                  position: 'fixed',
                                  background: bgColor[item.color_scheme],
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  zIndex: 1000,
                                  width: "100vw",
                                  height: "100vh",
                                  transform: "translateY(100%)"
                                }}
                              />
                            </TransitionPortal>
                          </div>
                        ) : (
                          <div key={index} className="decade-year">
                            <button type="button" onClick={() => {
                              fullpageApi.moveTo(_.toLower(decade.value));
                            }}>
                                <span className={`circle ${index === 2 ? `animate-circle ${item.color_scheme}-darken` : ''}`}></span>
                              <span className="year-label align-middle">{decade.value}</span>
                            </button>
                          </div>
                        )))}
                        <div id="decade-line" />
                      </div>
                    </Col>
                    <Col className="position-inherit">
                      <div className="decade-timeline-content justify-content-center justify-content-md-between">
                        {item.decades.map(
                          (node, index) => index === first && (
                            <div className="current-decade-placeholder animate__animated animate__fadeInDown px-sm-5" key={index}>
                              <div className="current-decade-mobile">
                                <h1 className="script-heading"
                                  dangerouslySetInnerHTML={{ __html: node.value.indexOf('Pre-') !== -1 ? _.replace(node.value, 'Pre-', '<span>Pre-</span>') : node.value }}
                                ></h1>
                                <TransitionLink
                                  preventScrollJump
                                  className={`mt-4 mt-md-0 py-2 px-3 ${item.color_scheme} d-inline-block font-italic decade-mobile-button`}
                                  to={_.kebabCase(node.value)}
                                  exit={{
                                    length: 1,
                                    trigger: ({ exit }) => horizontalAnimation(exit, `transition-cover-${node.value}`, 'right'),
                                  }}
                                  entry={{
                                    delay: .8,
                                    trigger: ({ entry, node }) => reveal(entry, node),
                                  }}
                                >
                                  <span>Explore Decade</span>
                                  <span className="long-arrow ml-2" />
                                </TransitionLink>
                              </div>
                            </div>
                          ),
                        )}
                        <div className={`story-lists-wrapper px-3 ml-0 px-md-0 pl-md-5 ml-md-4 mt-5 mt-sm-0 ${item.decades[first].value.indexOf('Pre-') !== -1 && 'pt-4'}`}>
                          <p className="site-label">All Stories</p>

                          <ul id="story-items" style={{ columnCount: stories.filter(story => story.decade[0] === item.decades[first].value).length < 6 ? '1' : '2' }}>

                            {stories.map(
                              (story, index) => story.decade
                                && story.decade.map(
                                  (year) => item.decades[first].value === year && (
                                    <li key={story.story_id}>
                                      <a
                                        href={`/${_.toLower(story.decade[0])}/${_.kebabCase(_.split(story.name, '.').join(''))}`}
                                      >
                                        {story.name}
                                      </a>
                                    </li>
                                  ),
                                ),
                            )}
                          </ul>
                        </div>
                      </div>
                      {item.decades.map(
                        (decade, index) => index === 1 && (
                          <div
                            key={index}
                            className={`${_.toLower(decade.value)}-bg-wrapper decade-bg-wrapper ${isHover ? 'decade-active' : ''}`}
                          >
                            <BackgroundImage src={_.toLower(decade.value)} />
                          </div>
                        ),
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            ))}
          </ReactFullpage.Wrapper>
        )}
      />

      <div className="scroll-down-navigator">
        <span className="icon"></span>
      </div>

      <SocialShare dataOptions={dataOptions} isHome={true} />
    </Layout>
  </>);
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
     allFile {
      nodes {
        publicURL
        name
      }
    }
    allDecades {
      nodes {
        value
        color_scheme
      }
    }
    allStories {
      nodes {
        story_id
        name
        story_content
        story_sources
        decade
        coverage
        author
        display_date
        geographic_area
        heritage
        keywords
        language
        start_date
        rights_license
        story_stream
        topics_themes
      }
    }
  }
`;

export default Home;