import React, { Component, useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ReactPlayer from 'react-player';
import { Container, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import Header from './header';
import landingPageTitle from '../../static/bcauh-title.png';

class Loader extends Component {

  state = {
    videoID: '',
    colorOverlay: '',
    isReady: false,
    duration: 0,
    isBuffering: false,
    bufferedDuration: 0,
    isMuted: true,
    isVideoEnded: false,
    revealTimeline: false
  }

  componentDidMount() {

    this.setState({
      colorOverlay: this.getRandomOverlayColor(),
      videoID: this.getRandomVideoBg(),
    })
  }

  getRandomOverlayColor = () => {
    const colorOverlay = ['dark-blue-scheme', 'orange-scheme', 'blue-scheme'];

    const randomColorOverlay = _.random(0, colorOverlay.length - 1);

    return colorOverlay[randomColorOverlay];
  }

  getRandomVideoBg = () => {
    // https://cdn.jwplayer.com/videos/DAecW9wk-iehAYMoO.mp4
    const landingPageVideos = [
      'https://cdn.jwplayer.com/videos/3eR9VNls-iehAYMoO.mp4',
      'https://cdn.jwplayer.com/videos/r8ObWtB6-iehAYMoO.mp4',
      'https://cdn.jwplayer.com/videos/DAecW9wk-iehAYMoO.mp4'
    ];

    const randomLandingPageVideo = _.random(0, landingPageVideos.length - 1);

    return landingPageVideos[randomLandingPageVideo];

  }

  onPlayerReady = () => {
    this.setState({
      isReady: true
    })
  }



  getDuration = (duration) => {
    this.setState({
      duration: duration
    })
  }

  onBuffer = (buffer) => {

    if (buffer.type === 'waiting') {
      this.setState({
        bufferedDuration: buffer.target.currentTime,
        isBuffering: true
      })
    }
  }

  onBufferEnd = (bufferEnd) => {
    if (bufferEnd.type === 'playing') {
      this.setState({
        isBuffering: false,
        duration: this.state.bufferedDuration > 0 ? this.state.duration - this.state.bufferedDuration : this.state.duration
      })
    }
  }

  setToggleAudio = () => {
    this.setState({
      isMuted: !this.state.isMuted
    })
  }

  onEnded = () => {
    this.setState({
      isVideoEnded: !this.state.isVideoEnded
    })

    this.revealTimeline();
  }

  revealTimeline = () => {
    if (!this.state.isMuted) {
      this.setState({
        isMuted: !this.state.isMuted
      })
    }

    this.setState({
      revealTimeline: true
    })

    window.setTimeout(() => {
      if (typeof window !== "undefined" && window.sessionStorage.getItem('splashFirstLoadDone')) {
        window.sessionStorage.setItem('splashFirstLoadDone', 0);
      }
    }, 2500);
  }

  render() {

    return (<>
      <div className={`section front-load-container text-center ${this.state.colorOverlay} ${this.state.revealTimeline ? 'animate__animated animate__delay-1s animate__fast animate__fadeOut exit' : ''}`}>
        <div id="video-landing-page-wrapper" className="">
          <div className="player-wrapper">
            <ReactPlayer
              className='react-player'
              url={this.state.videoID}
              playing={true}
              loop={false}
              playsinline={true}
              volume={1}
              muted={this.state.isMuted}
              width="auto"
              height="auto"
              onReady={this.onPlayerReady}
              onDuration={this.getDuration}
              onBuffer={this.onBuffer}
              onBufferEnd={this.onBufferEnd}
              onEnded={this.onEnded}
            // onPlay={onPlay}
            />
          </div>

          <div id="video-landing-title">
            <span className={`${this.state.revealTimeline ? 'animate__animated animate__fadeOut' : ''} site-title-logo`}>
              <img src={landingPageTitle} />
            </span>
            <p className={`${this.state.revealTimeline ? 'animate__animated animate__fadeOut' : ''} site-label text-white d-block d-md-none mt-5`}>Digital Timeline</p>

            <button type="button"
              id="mute-button"
              className={`${this.state.revealTimeline ? 'animate__animated animate__fadeOut' : ''}`}
              onClick={() => this.setToggleAudio()}
            >
              <span className="icon"><FontAwesomeIcon icon={this.state.isMuted ? faVolumeMute : faVolumeUp} /></span>
              <span className="label">{this.state.isMuted ? 'Audio off' : 'Audio on'}</span>
            </button>
          </div>
        </div>
        <div id="video-footer-wrapper" className={`${this.state.revealTimeline ? 'animate__animated animate__fadeOut' : ''}`}>
          <p className="site-label text-white d-none d-md-block">Entering the Digital Timeline in...</p>

          <div className="video-countdown">
            {!this.state.isVideoEnded && this.state.isReady && <svg className="d-none d-md-block">
              <circle r="30" cx="35" cy="35"
                style={{ animationDuration: `${this.state.duration + 1.25}s`, animationPlayState: this.state.isBuffering ? 'paused' : 'running' }}
              ></circle>
            </svg>}

            <button type="button" className={`scroll-button ${this.state.revealTimeline ? 'animate__animated animate__fadeOut' : ''}`} onClick={() => this.revealTimeline()}>
              <span className="scroll-icon"></span>
              <span className="spinner-circle"></span>
            </button>
          </div>
        </div>
      </div>
    </>);
  }
}


export default Loader;
