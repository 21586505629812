import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const BgImage = ({ src, className }) => {
  const data = useStaticQuery(graphql`
		query {
			images: allFile {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
		}
	`);

  const image = data.images.nodes.find((img) => img.name === src);

  if (!image) return null;

  return (
    <>
      {image !== undefined && (
        <ConditionalWrapper condition={className} wrapper={(children) => <div className={className}>{children}</div>}>
          <div
            className="fp-bg bg-container"
            style={{
              backgroundImage: `url(${image.childImageSharp.gatsbyImageData.images.fallback.src})`,
            }}
            data-src={image.childImageSharp.gatsbyImageData.images.fallback.src}
          />
        </ConditionalWrapper>
      )}
    </>
  );
};

export default BgImage;
